import React from 'react'
import { graphql } from "gatsby";
import { Container, Row, Col } from 'react-bootstrap'
import Layout from "../../components/Layout";
import SEO from "../../components/SEO";

const Solutions = ({ data }) => {

	const solutions_landing_small_medium_erequest = data.solutions_landing_small_medium_erequest.nodes;
	const solutions_landing_small_medium_esign = data.solutions_landing_small_medium_esign.nodes;
	const solutions_landing_small_medium_einvoice = data.solutions_landing_small_medium_einvoice.nodes;
	const solutions_landing_enterprise_p2p_detail = data.solutions_landing_enterprise_p2p_detail.nodes;
	const solutions_landing_enterprise_dms_detail = data.solutions_landing_enterprise_dms_detail.nodes;
	const solutions_landing_enterprise_collaboration_detail = data.solutions_landing_enterprise_collaboration_detail.nodes;
	const solutions_landing_powerfulecosystem = data.solutions_landing_powerfulecosystem.nodes;
	const solutions_landing_introduction = data.solutions_landing_introduction.nodes;

	return (
		<Layout>
            <SEO title="Solutions Portfolio" description="Appvity Solutions." meta={[{ name: `keywords`, content: ["eRequest", "collaboration", "task management", "issue management", "helpdesk system"] }]} pathname="/products" />
			<div className="main-content-padding">
				<div className="app-general-seciton app-solutionslanding-introduction" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
					<Container>
                        <Row className="app-row-introduction">
                            <Col className="app-col-introduction">
								<div className="app-solutionslanding-introduction-wrap">
									<div className="app-solutionslanding-introduction-detail">
										{solutions_landing_introduction.map((res) => (
											<div className="app-solutionslanding-introduction-detail-content" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
										))}
									</div>
								</div>
							</Col>
						</Row>
					</Container>
				</div>
				<div className="app-general-seciton app-small-medium" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
					<Container>
						<Row>
							<Col xs={12} md={12} lg={2}></Col>
							<Col xs={12} md={12} lg={8}>
								<div className="app-title">
									<h2>Small & Medium Business Solutions</h2>
								</div>
							</Col>
							<Col xs={12} md={12} lg={2}></Col>
						</Row>
						<div className="app-section-small-medium-detail">
							<Row className="app-row-top-align-center">
								<Col xs={12} md={12} lg={4} className="app-col-small-medium-1">
									{solutions_landing_small_medium_erequest.map((res) => (
										<div className="app-solutions-erequest" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
									))}
								</Col>
								<Col xs={12} md={12} lg={4} className="app-col-small-medium-2">
									{solutions_landing_small_medium_esign.map((res) => (
										<div className="app-solutions-esign" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
									))}
								</Col>
								<Col xs={12} md={12} lg={4} className="app-col-small-medium-3">
									{solutions_landing_small_medium_einvoice.map((res) => (
										<div className="app-solutions-einvoice" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
									))}
								</Col>
							</Row>
						</div>
					</Container>
					<div className="app-solutionslanding-spacing"></div>
				</div>
				<div className="app-general-seciton app-enterprise" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
					<Container>
						<Row>
							<Col xs={12} md={12} lg={2}></Col>
							<Col xs={12} md={12} lg={8}>
								<div className="app-title">
									<h2>Enterprise Solutions</h2>
								</div>
							</Col>
							<Col xs={12} md={12} lg={2}></Col>
						</Row>
						<div className="app-section-enterprise-detail">
							<Row className="app-row-margin-0 app-row-top-align-center">
								<Col xs={12} md={8} lg={6} className="app-col-padding-0 app-col-p2p-detail">
									{solutions_landing_enterprise_p2p_detail.map((res) => (
										<div className="app-solutions-p2p-detail" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
									))}
								</Col>
								<Col xs={12} md={4} lg={6} className="app-col-padding-0 app-col-p2p-img">
									<div className="app-solutions-p2p-img"></div>
								</Col>
							</Row>
							<Row className="app-row-margin-0 app-row-bottom-align-center">
								<Col xs={12} md={12} lg={6} className="app-col-padding-0 app-col-dms">
									<div className="app-solutionslanding-enterprise-dms">
										<Row className='app-row-margin-0'>
											<Col xs={12} md={8} lg={8} className="app-col-padding-0 app-col-dms-detail">
												{solutions_landing_enterprise_dms_detail.map((res) => (
													<div className="app-solutions-documentmamagementsystem-detail" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
												))}
											</Col>
											<Col xs={12} md={4} lg={4} className="app-col-padding-0 app-col-dms-img">
												<div className="app-solutions-documentmamagementsystem-img"></div>
											</Col>
										</Row>
									</div>
								</Col>
								<Col xs={12} md={12} lg={6} className="app-col-padding-0 app-col-collaboration">
									<div className="app-solutionslanding-enterprise-collaboration">
										<Row className='app-row-margin-0'>
											<Col xs={12} md={8} lg={8} className="app-col-padding-0 app-col-collaboration-detail">
												{solutions_landing_enterprise_collaboration_detail.map((res) => (
													<div className="app-solutions-collaboration-detail" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
												))}
											</Col>
											<Col xs={12} md={4} lg={4} className="app-col-padding-0 app-col-collaboration-img">
												<div className="app-solutions-collaboration-img"></div>
											</Col>
										</Row>
									</div>
								</Col>
							</Row>
						</div>
					</Container>
					<div className="app-solutionslanding-spacing"></div>
				</div>
				<div className="app-general-seciton app-powerful-ecosystem" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
					<Container>
						<Row>
							<Col xs={12} md={12} lg={2}></Col>
							<Col xs={12} md={12} lg={8}>
								{solutions_landing_powerfulecosystem.map((res) => (
									<div className="app-powerful-ecosystem-detail" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
								))}
							</Col>
							<Col xs={12} md={12} lg={2}></Col>
						</Row>
					</Container>
				</div>
				{/* <div className="app-general-seciton app-product-portfolio" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
					<Container>
						<Row>
							<Col xs={12} md={12} lg={2}></Col>
							<Col xs={12} md={12} lg={8}>
								<div className="app-title">
									<h2>Product Portfolio</h2>
								</div>
							</Col>
							<Col xs={12} md={12} lg={2}></Col>
						</Row>
						<div className="app-section-product-portfolio-detail">
							<Row className="app-row-top-align-center">
								<Col className="app-section-product-portfolio-detail-row-custom" xs={12} md={12} lg={4}>
									{product_portfolio_efiles.map((res) => (
										<div className="app-product-portfolio-detail-content-efiles" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
									))}
								</Col>
								<Col className="app-section-product-portfolio-detail-row-custom" xs={12} md={12} lg={4}>
									{product_portfolio_excelsync.map((res) => (
										<div className="app-product-portfolio-detail-content-excelsync" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
									))}
								</Col>
								<Col xs={12} md={12} lg={4}>
									{product_portfolio_erequest.map((res) => (
										<div className="app-product-portfolio-detail-content-erequest" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
									))}
								</Col>
							</Row>
							<Row className="app-row-bottom-align-center">
								<Col className="app-section-product-portfolio-detail-row-custom" xs={12} md={12} lg={4}>
									{product_portfolio_etask.map((res) => (
										<div className="app-product-portfolio-detail-content-etask" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
									))}
								</Col>
								<Col xs={12} md={12} lg={4}>
									{product_portfolio_ehelpdesk.map((res) => (
										<div className="app-product-portfolio-detail-content-ehelpdesk" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
									))}
								</Col>
							</Row>
						</div>
					</Container>
				</div>
				<div className="app-general-seciton app-appvity-extension-architect" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
					<Container>
						<Row>
							<Col xs={12} md={12} lg={2}></Col>
							<Col xs={12} md={12} lg={8}>
								<div className="app-title">
									<h2>Appvity eProducts Architecture</h2>
								</div>
							</Col>
							<Col xs={12} md={12} lg={2}></Col>
						</Row>
						<div className="app-section-appvity-extension-architect">
							<img src="/app-appvity-extension-architect.svg" alt="appvity extension architect img" />
							<img className="app-appvity-extension-architect-mobile-tablet" src="/app-appvity-extension-architect-mobile-tablet.svg" alt="appvity extension architect mobile tablet img" />
						</div>
					</Container>
				</div>
				<div className="app-general-seciton app-productlanding-msteam-cloud-platform" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
					<Container>
						{msteam_cloud_platform.map((res) => (
							<div className="app-productlanding-msteam-cloud-platform-content-left" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
						))}
					</Container>
					<div className="app-productlanding-msteam-cloud-platform-content-right"></div>
				</div>
				<div className="app-general-seciton app-productlanding-automate-work-processes-repetitive-tasks" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
					<Container>
						{automate_work_processes_repetitive_tasks.map((res) => (
							<div className="app-productlanding-automate-work-processes-repetitive-tasks-content-right" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
						))}
					</Container>
					<div className="app-productlanding-automate-work-processes-repetitive-tasks-content-left"></div>
				</div>
				<div className="app-general-seciton app-productlanding-manage-daily-works" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
					<Container>
						{manage_daily_works.map((res) => (
							<div className="app-productlanding-manage-daily-works-content-left" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
						))}
					</Container>
					<div className="app-productlanding-manage-daily-works-content-right"></div>
				</div>
				<div className="app-general-seciton app-power-tool" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                    <Container>
                        <div className="app-section-power-tool-detail">
                            <Row>
                                <Col xs={12} md={12} lg={5}>
									{power_tools.map((res) => (
										<div className="app-power-tool-detail-left" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
									))}
                                </Col>
                                <Col xs={12} md={12} lg={1}></Col>
                                <Col xs={12} md={12} lg={6}>
                                    <div className="app-power-tool-detail-right">
										<Row>
											<Col>
												<img src="/app-power-tool-left.jpg" alt="app power tool left" />
											</Col>
											<Col>
												<img src="/app-power-tool-right.jpg" alt="app power tool right" />
											</Col>
										</Row>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div> */}
			</div>
		</Layout>
	)
}

export default Solutions


export const query = graphql`
    query SolutionsLandingPage {
		solutions_landing_introduction: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "solutions_landing_introduction" } } }
        ) {
            nodes {
                html
                id
            }
        }
		solutions_landing_small_medium_einvoice: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "solutions_landing_small_medium_einvoice" } } }
        ) {
            nodes {
                html
                id
            }
        }
		solutions_landing_small_medium_erequest: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "solutions_landing_small_medium_erequest" } } }
        ) {
            nodes {
                html
                id
            }
        }
		solutions_landing_small_medium_esign: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "solutions_landing_small_medium_esign" } } }
        ) {
            nodes {
                html
                id
            }
        }
		solutions_landing_enterprise_p2p_detail: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "solutions_landing_enterprise_p2p_detail" } } }
        ) {
            nodes {
                html
                id
            }
        }
		solutions_landing_enterprise_dms_detail: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "solutions_landing_enterprise_dms_detail" } } }
        ) {
            nodes {
                html
                id
            }
        }
		solutions_landing_enterprise_collaboration_detail: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "solutions_landing_enterprise_collaboration_detail" } } }
        ) {
            nodes {
                html
                id
            }
        }
		solutions_landing_powerfulecosystem: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "solutions_landing_powerfulecosystem" } } }
        ) {
            nodes {
                html
                id
            }
        }
    }
`;